/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import url("../assets/fonts/Lato/stylesheet.css");

* {
    font-family: 'Lato';
}

.internal-header {
    display: flex;
    height: 90px;
    background-image: url(../assets/gradient-header-2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;

    .internal-title {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-size: 1.8em;
        color: white;
        align-items: center;
        height: 90px;
    }

    .actions-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.input-search {
    .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px !important;
        border-width: 1px;
        border-color: #848484 !important;
    }
}

.external-stepper {

    mat-step-header,
    .mat-step-header,
    .mat-horizontal-stepper-header {
        height: 100px !important;
        overflow-y: visible !important;
        overflow-x: visible !important;
        overflow: visible !important;
    }

    .mat-step-icon {
        display: none;
    }

    .mat-horizontal-stepper-header {
        pointer-events: none !important;
    }
}

.ok-action-button {
    background-color: #47b0bb !important;
    border-radius: 0.5rem !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.ko-action-button {
    background-color: #ffffff !important;
    border-radius: 0.5rem !important;
    --tw-text-opacity: 1 !important;
    color:  #5b5b5b !important;
    border-style: solid !important;
    --tw-border-opacity: 1 !important;
    border-color: #5b5b5b !important;
    border-width: 2px !important;
}

.delete-image-button {
    background-color: #c43f3f !important;
    border-radius: 0.5rem !important;
    --tw-text-opacity: 1 !important;
    color: #ffffff !important;
}

.new-action-button {
    background-color: #ffffff !important;
    border-radius: 0.9rem !important;
    --tw-text-opacity: 1 !important;
    color: #47b0bb !important;
    border-style: solid !important;
    --tw-border-opacity: 1 !important;
    border-color: #47b0bb !important;
    border-width: 2px !important;
}

.login {

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
        border-color: #ffffff !important;
        background-color: transparent !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: #ffffff !important;
    }
}

.delete-action-button {
    background-color: #ffffff !important;
    border-radius: 0.5rem !important;
    --tw-text-opacity: 1 !important;
    color: #c43f3f !important;
    border-style: solid !important;
    --tw-border-opacity: 1 !important;
    border-color: #c43f3f !important;
    border-width: 2px !important;
}

.theme-default .light .mat-step-header .mat-step-icon, .theme-default.light .mat-step-header .mat-step-icon {
    background-color: white;
    color: #9b9b9b;
    border-width: 2px;
    border-color: #9b9b9b;
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: #e76f51 !important;
    color: white !important;
    border-width: 0px !important;
    +.mat-step-label {
        color: #9b9b9b !important;
    }
}

.theme-default .light .mat-step-header .mat-step-icon-selected, .theme-default .light .mat-step-header .mat-step-icon-state-done, .theme-default .light .mat-step-header .mat-step-icon-state-edit, .theme-default.light .mat-step-header .mat-step-icon-selected, .theme-default.light .mat-step-header .mat-step-icon-state-done, .theme-default.light .mat-step-header .mat-step-icon-state-edit {
    background-color: #e76f51;
    color: white;
    border-width: 0px !important;
}